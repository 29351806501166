import styles from './styles.module.css'
import { TextInputField } from '../../entities/TextInputField'
import { SendButton } from '../../entities/SendButton'
import React, { type SyntheticEvent, useCallback, useState } from 'react'
import { Alert } from '@mui/material'
import { SelectionMenu } from '../../entities/SelectionMenu'
import { type TUser, type TUserResponse } from '../../pages/AuthPage/types'
import { AuthService } from '../../pages/AuthPage/auth-service'
import { loadOperatorsList } from '../../app/store/sagas/root'

const dict = {
  role: 'Роль пользователя'
}

const operatoresTypeSelections = [
  {
    label: 'Не указано',
    value: ''
  },
  {
    label: 'Оператор',
    value: 'operator'
  },
  {
    label: 'Администратор',
    value: 'administrator'
  }
]

export function OperatoresCreationForm () {
  const [userName, setUserName] = useState('')
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('')
  const [token, setToken] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const [resultMessage, setResultMessage] = useState('')

  const onClickCreate = useCallback(async () => {
    const newUser: TUser = {
      login: login,
      password: password,
      name: userName,
      role: role
    }

    const authService = AuthService()
    const results: TUserResponse = await authService.createUser(newUser, token)

    if (results.message) {
      setTimeout(() => { setErrorMessage('') }, 5000)
      setErrorMessage(results.message); 
      return
    }
    setTimeout(() => { setResultMessage('') }, 5000)
    setResultMessage('Пользователь успешно создан')

    await loadOperatorsList()
  }, [userName, login, password, role, token])

  return <form className={styles.PlayersCreationForm}>
        <TextInputField value={userName} className={styles.InputFields} id={'UserName'} label={'Имя пользователя'} onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setUserName(e.currentTarget.value) }} />

        <div className={styles.CountryCityRow}>
            <TextInputField className={styles.InputFields} id={'Login'} label={'Логин'} onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setLogin(e.currentTarget.value) }} />
            <TextInputField className={styles.InputFields} id={'Password'} label={'Пароль'} type={'password'} onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setPassword(e.currentTarget.value) }} />
        </div>

        <div className={styles.WeightHeightRow}>
            <div className={styles.SelectorWrapper}>
                <SelectionMenu 
                  selectionVariants={operatoresTypeSelections} 
                  label={dict.role} 
                  onSelect={e => { setRole(e.value) }}
                  selectedValue={role}
                  />
            </div>
            <TextInputField className={styles.InputFields} id={'Token'} label={'Токен'} type={'password'} onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => { setToken(e.currentTarget.value) }} />
        </div>

        <div className={styles.WeightHeightRow}>
            <SendButton className={styles.InputFields} label={'Очистить'} onClick={() => {}} />
            <SendButton className={styles.InputFields} label={'Создать'} onClick={onClickCreate} />
        </div>
        <div></div>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {resultMessage && <Alert severity="success">{resultMessage}</Alert>}
    </form>
}
